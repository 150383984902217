<template>
  <!--start booking receipt page-->
  <v-container fluid grid-list-md fill-height>
    <v-card
      id="printContent"
      tile
      elevation="0"
      :width="$vuetify.breakpoint.smAndDown ? '85%' : '700px'"
      class="pt-6 pb-4 mx-auto"
    >
      <v-sheet
        tile
        elevation="0"
        color="transparent"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-auto px-8'"
      >
        <div>
          <v-img
            class="justify-start mb-10 left"
            left
            contain
            width="200"
            :src="logo"
          ></v-img>
        </div>

        <div class="d-flex justify-space-between mb-12">
          <div>
            <span class="font-weight-medium mb-2">
              {{ company ? $t("generic.lang_company") + " " + company : "" }}
            </span>
            <br />
            <span v-text="this.location.street"></span>
            <br />
            <span v-text="this.location.zip_code + ' ' + this.location.city">
            </span>
          </div>

          <div v-if="$vuetify.breakpoint.mdAndUp">
            <span>{{ $t("generic.lang_posting_date") }} {{ PostingDate }}</span>
            <br />
            <span>{{ $t("generic.lang_reservation_date") }} {{ date }}</span>
          </div>

          <div v-if="$vuetify.breakpoint.smAndDown">
            <span>{{ $t("generic.lang_reservation_date") }}</span> <br />
            <span>{{ date }}</span>
          </div>
        </div>

        <div>
          <h2>{{ $t("generic.lang_reservation_confirmation") }}</h2>
          <br />
          <span class="font-weight-bold">
            {{ $t("generic.lang_hello_mr") }} {{ last_name }},
          </span>
          <br />
          <span>
            {{ $t("generic.lang_confirmation_text") }}
          </span>
        </div>
      </v-sheet>

      <v-sheet
        id="printWithColor"
        elevation="0"
        color="grey lighten-3"
        :class="
          $vuetify.breakpoint.mdAndUp
            ? 'd-flex flex-wrap rounded-lg justify-space-between mx-auto my-8 py-6 px-6'
            : 'rounded-lg text-center justify-center mx-auto my-8 py-6 px-6'
        "
      >
        <div>
          <h4 class="font-weight-bold">
            {{ $t("generic.lang_booking_info") }}
          </h4>

          <br v-if="$vuetify.breakpoint.mdAndUp" />

          <span>
            {{ $t("generic.lang_name") }}
            <span class="indigo--text" v-text="first_name + ' ' + last_name" />
          </span>

          <br />

          <span>
            {{ $t("generic.lang_date") }}
            <span class="indigo--text" v-text="date" />
          </span>

          <br />

          <span>
            {{ $t("generic.lang_time") }}
            <span class="indigo--text" v-text="time + ' Uhr'" />
          </span>

          <br />

          <span>
            {{ $t("generic.lang_number_people") }}
            <span class="indigo--text" v-text="numberPersons" />
          </span>

          <br />

          <v-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            id="dontPrint"
            depressed
            dark
            color="indigo"
            class="text-capitalize px-8 mt-16"
            @click="getConfirmation()"
          >
            {{ $t("generic.lang_download_pdf") }}
          </v-btn>
        </div>

        <div>
          <br v-if="$vuetify.breakpoint.smAndDown" />

          <div class="justify-center text-center">
            <div id="QrCodeImg"></div>
          </div>

          <br />

          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            class="caption text-center"
            style="width: 200px;"
          >
            {{ $t("generic.lang_scan_qr") }}
          </div>

          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            id="dontPrint"
            depressed
            dark
            color="indigo"
            class="text-capitalize px-8"
            @click="getConfirmation()"
          >
            {{ $t("generic.lang_download_pdf") }}
          </v-btn>

          <!--<v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            id="dontPrint"
            depressed
            dark
            color="indigo"
            class="text-capitalize px-6"
            @click="generatQr()"
          >
            Download QrCode
          </v-btn>-->
        </div>
      </v-sheet>

      <v-sheet
        tile
        elevation="0"
        color="transparent"
        :class="$vuetify.breakpoint.smAndDown ? '' : 'mx-auto px-8'"
      >
        <div class="mb-15">
          <p>
            {{ $t("generic.lang_pleasant_stay") }}
          </p>
          <p>{{ $t("generic.lang_kind_regards") }}</p>
          <p class="font-weight-medium">{{ $t("generic.lang_team") }}</p>
        </div>

        <div class="mt-15 mb-12">
          <v-divider width="300px" class="justify-center mx-auto mb-2" />
          <p class="text-center">
            {{ $t("generic.lang_letter_binding") }}
          </p>
        </div>

        <div>
          <h6 class="text-center black--text">
            {{ $t("generic.lang_reciept_copy_right") }}
          </h6>
        </div>
      </v-sheet>
    </v-card>
  </v-container>
  <!--end booking receipt page-->
</template>

<script>
/*import plugins*/
import { createNamespacedHelpers } from "vuex";

import qrcode from "qrcode-generator";

export default {
  name: "BookingReceipt",

  data() {
    return {
      fileContent: "",
      icalDtEnd: "",
      nowDate: new Date(),
    };
  },

  beforeRouteLeave() {
    window.indexedDB.deleteDatabase("localforage");
    window.localStorage.clear();

    //next("/");
  },

  computed: {
    ...createNamespacedHelpers("reservationTypes").mapState([
      "numberPersons",
      "reservationTypeName",
      "reservationTypeMaxTime",
    ]),
    ...createNamespacedHelpers("dateTime").mapState(["date", "time"]),
    ...createNamespacedHelpers("customerData").mapState([
      "first_name",
      "last_name",
      "phone",
      "email",
    ]),
    ...createNamespacedHelpers("config").mapState(["config"]),

    PostingDate() {
      return (
        this.nowDate.getFullYear() +
        "-" +
        this.nowDate.getDay() +
        "-" +
        this.nowDate.getDate()
      );
    },

    logo() {
      return this.config && this.config.logo;
    },

    company() {
      return this.config && this.config.company;
    },

    location() {
      return this.config && this.config.location;
    },
  },

  mounted() {
    this.addQrContent();
  },

  methods: {
    getConfirmation() {
      window.print();
    },

    download(filename, text) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/calendar;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },

    generatQr() {
      // Start ics file download.
      var filename = "yourBooking.ics";
      this.download(filename, this.fileContent);
    },

    addEndTime() {
      let dtEnd = eval(
        this.time.substring(0, 2) * 3600 +
          this.time.substring(3, 5) * 60 +
          this.reservationTypeMaxTime
      );

      var t = new Date(dtEnd * 1000);
      var hh = t.getUTCHours();
      var mm = t.getUTCMinutes();
      var ss = t.getSeconds();

      if (hh < 10) {
        hh = "0" + hh;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      if (ss < 10) {
        ss = "0" + ss;
      }

      this.icalDtEnd = hh + ":" + mm + ":" + ss;
    },

    addQrContent() {
      //start generate QR Content

      this.addEndTime();

      this.fileContent =
        "BEGIN:VCALENDAR\n" +
        "PRODID:-//Google Inc//Google Calendar 70.9054//EN\n" +
        "VERSION:2.0\n" +
        "CALSCALE:GREGORIAN\n" +
        "METHOD:PUBLISH\n" +
        "BEGIN:VEVENT\n" +
        "DTSTART:" +
        this.date.replace(/-/g, "") +
        "T" +
        this.time.replace(":", "") +
        "00Z" +
        "\nDTEND:" +
        this.date.replace(/-/g, "") +
        "T" +
        this.icalDtEnd.replace(/:/g, "") +
        "Z" +
        "\nDTSTAMP:" +
        new Date()
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "") +
        "T" +
        new Date()
          .toISOString()
          .substr(11, 8)
          .replace(/:/g, "") +
        "Z\n" +
        "UID:uniqueid_123@email.com\n" +
        "CREATED:" +
        new Date()
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "") +
        "T" +
        new Date()
          .toISOString()
          .substr(11, 8)
          .replace(/:/g, "") +
        "Z\n" +
        "DESCRIPTION: Number Persons: " +
        this.numberPersons +
        "  |  Reservation Type: " +
        this.reservationTypeName +
        "\nLAST-MODIFIED:" +
        new Date()
          .toISOString()
          .substr(0, 10)
          .replace(/-/g, "") +
        "T" +
        new Date()
          .toISOString()
          .substr(11, 8)
          .replace(/:/g, "") +
        "Z\n" +
        "LOCATION:" +
        this.location.country +
        ", " +
        this.location.city +
        " " +
        this.location.zip_code +
        ", " +
        this.location.street;
      "\nSEQUENCE:0\n" +
        "STATUS:CONFIRMED\n" +
        "SUMMARY:Restaurant booking\n" +
        "TRANSP:OPAQUE\n" +
        "END:VEVENT\n" +
        "END:VCALENDAR";

      var typeNumber = 15;
      var errorCorrectionLevel = "L";

      var qr = qrcode(typeNumber, errorCorrectionLevel);
      qr.addData(this.fileContent);
      qr.make();

      document.getElementById("QrCodeImg").innerHTML = qr.createImgTag();
    },
  },
};
</script>

<style lang="scss">
@media print {
  #dontPrint {
    display: none !important;
  }

  #printWithColor {
    background-color: rgb(199, 199, 199) !important;
    -webkit-print-color-adjust: exact;
  }
}
</style>
